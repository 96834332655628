import { FamilyBonusCard } from "../../../model/family.bonus.card";
import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useLocale } from "../../../hooks/useLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    divider: {
      marginTop: "10px",
    },
  }),
);

export default function FbcCardLayout({
  card,
  style,
}: {
  card: FamilyBonusCard;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const locale = useLocale();

  return (
    <Grid
      container
      className={classes.section}
      spacing={0}
      style={{ ...style }}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems={"flex-start"}>
        <Grid item container direction="column" xs={6}>
          <Typography variant={"h6"}>
            {intl.get(`my_family_bonus_cards.show_card.label_card_id`)}
          </Typography>
          <Typography variant={"h2"}>{card.fbc_card_number}</Typography>
        </Grid>

        <Grid item container direction="column" alignItems={"flex-end"} xs={6}>
          <Typography variant={"h6"}>
            {intl.get(`my_family_bonus_cards.show_card.label_valid_until`)}
          </Typography>
          <Typography variant={"h2"}>
            {format(parseISO(card.valid_until), "dd.MM.yyyy", {
              locale: locale.dateFns,
            })}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12} container direction="column" alignItems={"center"}>
        {card.qr_code_format === "jpg" && (
          <img
            src={`data:image/jpg;base64,${card.qr_code_data}`}
            style={{ width: "100%", aspectRatio: "1" }}
          />
        )}
        {card.qr_code_format === "svg" && (
          <img
            src={`data:image/svg+xml;base64,${card.qr_code_data}`}
            style={{ width: "100%", aspectRatio: "1" }}
          />
        )}
      </Grid>
    </Grid>
  );
}
