import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import EventCard from "./EventCard";
import { IEvent } from "../model/event";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
    },
    scrollContainer: {
      gridRow: 1,
      gridColumn: 1,
      zIndex: 5,
      overflowX: "auto",
      maxWidth: "100vw",
      gap: 26,
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        gap: 15,
      },
    },
    eventCard: {
      paddingBottom: 62 - 26, // subtract gap
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 39 - 15,
      },
    },
  }),
);

interface Props {
  events: IEvent[];
  onSelected: (event) => void;
  hideFavoriteIcon?: boolean;
  hideTags?: boolean;
  category?: string;
}

export default function EventGrid(props: Props) {
  const { events, onSelected, hideFavoriteIcon, hideTags, category } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <Grid
        container
        justify={isMobile ? "center" : "flex-start"}
        direction={"row"}
        className={classes.scrollContainer}
        item>
        {events.map((event, index) => {
          return (
            <Grid item key={event.id} className={classes.eventCard}>
              <EventCard
                showFbcInfo={category === "family_bonus_card"}
                largeImage={false}
                event={event}
                onSelected={onSelected}
                variant={"grid"}
                hideFavoriteIcon={hideFavoriteIcon}
                hideTags={hideTags}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
