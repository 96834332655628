import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { FamilyBonusCard } from "../../../model/family.bonus.card";
import { useDispatch } from "react-redux";
import { useState } from "react";
import useDialogReset from "../../../components/dialog/useDialogReset";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import { isMobile } from "react-device-detect";
import intl from "react-intl-universal";
import LoadingButton from "../../../components/LoadingButton";
import * as React from "react";
import * as FamilyBonusCardActions from "../../../actions/family.bonus.cards";
import * as UserActions from "../../../actions/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
      paddingTop: 0,
      overflow: "hidden",
    },
  }),
);

export default function DialogConfirmCardDeletion({
  handler,
  onSuccessfullyDeleted,
}: {
  handler: DialogHandler<FamilyBonusCard>;
  onSuccessfullyDeleted: () => void;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useDialogReset(handler, () => {
    setDeleting(false);
    setError(false);
  });

  async function deleteCard() {
    setDeleting(true);
    dispatch<any>(
      FamilyBonusCardActions.deleteFamilyBonusCard({
        cardId: handler.payload.fbc_card_number,
        completion: () => {
          onSuccessfullyDeleted();
          setDeleting(false);
          handler.close();
        },
        error: () => {
          setError(true);
          setDeleting(false);
        },
      }),
    );
  }

  if (!handler.payload) {
    return null;
  }
  return (
    <HandlerDialog
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogTitle>
        {intl.get(`my_family_bonus_cards.delete_card.title`)}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {error && (
          <Typography variant="body2" color={"error"}>
            {intl.get(`my_family_bonus_cards.delete_card.error`)}
          </Typography>
        )}
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: intl.get("my_family_bonus_cards.delete_card.message", {
              card_number: handler.payload.fbc_card_number,
            }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={deleting}
          onClick={() => {
            deleteCard();
          }}
          buttonLabel={intl.get(
            `my_family_bonus_cards.delete_card.action_delete`,
          )}
        />
        <Button
          color="secondary"
          onClick={() => {
            handler.close();
          }}>
          {intl.get(`my_family_bonus_cards.delete_card.action_cancel`)}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
