import React, { useEffect, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import EventCard from "./EventCard";
import { IEvent } from "../model/event";
import { isMobile } from "react-device-detect";
import useWindowSize from "../hooks/useWindowSize";
import { ColorSvg } from "./ColorSvg";
import ScrollArrowActive from "../assets/icons/ScrollArrowActive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
    },
    arrowSvg: {
      width: 18,
      height: 18,
    },
    arrow: {
      marginTop: "auto",
      marginBottom: "auto",
      backgroundColor: "#f6f6f6",
      width: 50,
      height: 50,
      pointerEvents: "all",
      boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
      "&:hover": {
        backgroundColor: "#f6f6f6",
        boxShadow: "0px 4.5px 8px rgba(0,0,0,0.16)",
      },
      "&.arrowLeft": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
      "&.arrowRight": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    arrowContainer: {
      gridRow: 1,
      gridColumn: 1,
      zIndex: 10,
      display: "flex",
      width: 50,
      height: theme.carousel.imageSize,
      pointerEvents: "none",
      "&.arrowRight": {
        marginLeft: "auto",
      },
    },
    scrollContainer: {
      gridRow: 1,
      gridColumn: 1,
      zIndex: 5,
      overflowX: "auto",
      maxWidth: "100vw",
      scrollSnapType: isMobile ? "x mandatory" : "",
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
    },
    carouselItem: {
      scrollSnapAlign: "start",
      paddingLeft: theme.carousel.itemSpacing,
      "&.firstItem": {
        paddingLeft: theme.carousel.horizontalContentPadding,
        [theme.breakpoints.down("md")]: {
          paddingLeft: 15,
        },
      },
      "&.lastItem": {
        paddingRight: theme.carousel.horizontalContentPadding,
        [theme.breakpoints.down("md")]: {
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
    },
    autoMarginLeft: {
      marginLeft: "auto",
    },
    autoMarginRight: {
      marginRight: "auto",
    },
  }),
);

interface Props {
  events: IEvent[];
  onSelected: (event) => void;
  gravity?: "left" | "center";
  hideArrows?: boolean;
  onCanScrollChanged?: (canScroll: boolean) => void;
  category?: string;
}

export default function EventCarousel(props: Props) {
  const {
    events,
    onSelected,
    gravity,
    hideArrows,
    onCanScrollChanged,
    category,
  } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  let { events: scrollEvents } = useDraggable(ref);
  if (isMobile) {
    // @ts-ignore
    scrollEvents = {};
  }

  // note: smooth scroll currently not supported in Safari, but will be added in Safari 15.4
  // see https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes#Web-API
  const smoothScrollX = (elementRef: any, amount: number) => {
    const options: ScrollToOptions = { left: amount, behavior: "smooth" };
    elementRef.current.scrollBy(options);
  };

  const scrollRight = () => {
    smoothScrollX(
      ref,
      getScrollAmountForNextCardStartPosition(scrollState.position, "right"),
    );
  };

  const scrollLeft = () => {
    smoothScrollX(
      ref,
      getScrollAmountForNextCardStartPosition(scrollState.position, "left"),
    );
  };

  const [scrollState, setScrollState] = useState({
    position: 0,
    canScrollLeft: false,
    canScrollRight: false,
  });

  const carouselScrollWidth = ref && ref.current ? ref.current.scrollWidth : 0;
  const carouselClientWidth = ref && ref.current ? ref.current.clientWidth : 0;

  const windowSize = useWindowSize();

  const [canScroll, setCanScroll] = useState(false);

  const updateCanScroll = () => {
    if (onCanScrollChanged) {
      if (canScroll != (ref.current.scrollWidth != ref.current.clientWidth)) {
        setCanScroll(ref.current.scrollWidth != ref.current.clientWidth);
        onCanScrollChanged(ref.current.scrollWidth != ref.current.clientWidth);
      }
    }
  };

  useEffect(() => {
    updateCanScroll();
    updateScrollPosition();
  }, [
    onCanScrollChanged,
    ref,
    carouselClientWidth,
    carouselScrollWidth,
    windowSize.width,
  ]);

  const updateScrollPosition = () => {
    if (ref && ref.current) {
      setScrollState({
        position: ref.current.scrollLeft,
        canScrollLeft:
          ref.current.scrollLeft > theme.carousel.horizontalContentPadding,
        canScrollRight:
          ref.current.scrollWidth - ref.current.scrollLeft >
          ref.current.clientWidth + theme.carousel.horizontalContentPadding,
      });
    }
  };

  const getScrollPositionForIndex = (index: number) => {
    return Math.max(
      0,
      index * theme.carousel.imageSize +
        Math.max(0, index - 1) * theme.carousel.itemSpacing +
        theme.carousel.horizontalContentPadding,
    );
  };

  const getScrollAmountForNextCardStartPosition = (
    scrollPosition: number,
    direction: "left" | "right",
  ) => {
    const cardWidth = theme.carousel.imageSize;
    const spacing = theme.carousel.itemSpacing;
    const paddingLR = theme.carousel.horizontalContentPadding;

    const scrollAmountInNumberOfCards = 2;

    const index = Math.max(
      0,
      Math.round((scrollPosition - paddingLR) / (cardWidth + spacing)),
    );

    const newPosition =
      direction == "left"
        ? index <= scrollAmountInNumberOfCards
          ? 0
          : getScrollPositionForIndex(index - scrollAmountInNumberOfCards)
        : getScrollPositionForIndex(index + scrollAmountInNumberOfCards);

    return newPosition - scrollPosition - paddingLR + spacing;
  };

  useEffect(() => {
    updateScrollPosition();
  }, [ref]);

  return (
    <div className={classes.root}>
      <Grid
        {...scrollEvents}
        ref={ref}
        container
        direction={"row"}
        wrap="nowrap"
        onScroll={updateScrollPosition}
        className={classes.scrollContainer}>
        {events.map((event, index) => {
          return (
            <Grid
              item
              key={event.id}
              style={{
                marginLeft: gravity == "center" && index == 0 ? "auto" : 0,
                marginRight:
                  gravity == "center" && index == events.length - 1
                    ? "auto"
                    : 0,
              }}
              className={[
                classes.carouselItem,
                index === 0 ? "firstItem" : undefined,
                index === events.length - 1 ? "lastItem" : undefined,
              ]
                .filter((item) => item)
                .join(" ")}>
              <EventCard
                showFbcInfo={category === "family_bonus_card"}
                largeImage={true}
                event={event}
                onSelected={onSelected}
                variant={"carousel"}
              />
            </Grid>
          );
        })}
      </Grid>
      {!hideArrows && scrollState.canScrollLeft && (
        <div className={[classes.arrowContainer, "arrowLeft"].join(" ")}>
          <IconButton
            onClick={scrollLeft}
            color={"primary"}
            className={[classes.arrow, "arrowLeft"].join(" ")}>
            <ColorSvg
              svg={ScrollArrowActive}
              color={theme.palette.primary.main}
              className={classes.arrowSvg}
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
        </div>
      )}
      {!hideArrows && scrollState.canScrollRight && (
        <div className={[classes.arrowContainer, "arrowRight"].join(" ")}>
          <IconButton
            onClick={scrollRight}
            color={"primary"}
            className={[classes.arrow, "arrowRight"].join(" ")}>
            <ColorSvg
              svg={ScrollArrowActive}
              color={theme.palette.primary.main}
              className={classes.arrowSvg}
            />
          </IconButton>
        </div>
      )}
    </div>
  );
}
