import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import React, { useEffect } from "react";
import * as EventActions from "../../actions/events";
import { useHistory } from "react-router";
import { Section } from "../../model/section";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";
import { getCanonicalUrl } from "../../utils/utils";
import BaseEventGridPage from "../base/BaseEventGridPage";
import useSortQuery from "../../hooks/useSortQuery";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useUser } from "../../hooks/useUser";

export default function CategoryPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const categoryId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1,
  );

  const [selectedSort, setSelectedSort] = useSortQuery();

  useEffect(() => {
    dispatch(EventActions.getCategoryEvents(categoryId, selectedSort));
  }, [categoryId, dispatch, selectedSort]);

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  const data = useSelector((state: RootState) => {
    return {
      loading: state.categoryState.loading,
      events: state.categoryState.events,
    };
  });

  const user = useUser();

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {intl.get(`seo.categories.title`) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
      </Helmet>

      <BaseEventGridPage
        category={categoryId}
        title={Section.getCategoryDisplayName(categoryId, user)}
        events={data.events}
        sort={selectedSort}
        onSortChanged={setSelectedSort}
        loading={data.loading === true}
      />
    </>
  );
}
