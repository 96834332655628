import { DialogHandler } from "./useDialogHandler";
import { DialogContext } from "./DialogContext";
import { Dialog, DialogProps } from "@material-ui/core";

interface Props extends Omit<DialogProps, "open"> {
  handler: DialogHandler<any>;
}

export default function HandlerDialog({ handler, onClose, ...props }: Props) {
  const handleClose = (event: any, reason: any) => {
    handler.handleDialogClose(event, reason);
    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <DialogContext handler={handler}>
      {handler.isOpen && (
        <Dialog {...props} open={handler.isOpen} onClose={handleClose} />
      )}
    </DialogContext>
  );
}
