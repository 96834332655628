import React from "react";
import {
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { FamilyBonusCard } from "../../../model/family.bonus.card";
import intl from "react-intl-universal";
import { format, parseISO } from "date-fns";
import { useLocale } from "../../../hooks/useLocale";
import AddToGoogleWallet from "../../../assets/images/add_to_google_wallet.svg";
import AddToAppleWallet from "../../../assets/images/add_to_apple_wallet.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  MuiBreakpoint,
  useMuiBreakpoint,
} from "../../../hooks/useMuiBreakpoint";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardCellCenter: {
      textAlign: "center",
      borderBottom: 0,
    },
    cardCell: {
      borderBottom: 0,
    },
  }),
);

export default function ManageFbcCards({
  cards,
  onShowCard,
  onAddToAppleWallet,
  onAddToGoogleWallet,
  onDelete,
  appleWalletLoadingCardId,
  googleWalletLoadingCardId,
}: {
  cards: FamilyBonusCard[];
  onShowCard: (card: FamilyBonusCard) => void;
  onAddToAppleWallet: (card: FamilyBonusCard) => void;
  onAddToGoogleWallet: (card: FamilyBonusCard) => void;
  onDelete: (card: FamilyBonusCard) => void;
  appleWalletLoadingCardId?: string;
  googleWalletLoadingCardId?: string;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const locale = useLocale();
  const muiBreakpoint = useMuiBreakpoint();
  const isSmall = muiBreakpoint <= MuiBreakpoint.md;

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "15px", marginBottom: "15px" }}>
        {cards.map((value, index) => {
          return (
            <>
              <Grid
                item
                spacing={2}
                container
                xs={12}
                direction={isSmall ? "column" : "row"}
                wrap={"nowrap"}>
                {!isSmall && (
                  <Grid item container direction="row">
                    <Grid item xs={12}>
                      <Typography variant={"h5"}>
                        {intl.get("my_family_bonus_cards.value_card_id", {
                          card_id: value.fbc_card_number,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"body2"}>
                        {intl.get("my_family_bonus_cards.value_valid_until", {
                          valid_until: format(
                            parseISO(value.valid_until),
                            "dd.MM.yyyy",
                            {
                              locale: locale.dateFns,
                            },
                          ),
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {isSmall && (
                  <Grid
                    item
                    container
                    direction={"row"}
                    wrap={"nowrap"}
                    alignContent={"space-between"}>
                    <Grid item container direction="row">
                      <Grid item xs={12}>
                        <Typography variant={"h5"}>
                          {intl.get("my_family_bonus_cards.value_card_id", {
                            card_id: value.fbc_card_number,
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={"body2"}>
                          {intl.get("my_family_bonus_cards.value_valid_until", {
                            valid_until: format(
                              parseISO(value.valid_until),
                              "dd.MM.yyyy",
                              {
                                locale: locale.dateFns,
                              },
                            ),
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "#FF6EA8",
                          color: "white",
                        }}
                        onClick={() => {
                          onDelete(value);
                        }}
                        color="secondary">
                        {intl.get("my_family_bonus_cards.action_delete")}
                      </Button>
                    </Grid>
                  </Grid>
                )}

                <Grid item style={{ flexShrink: 0 }}>
                  <Button
                    style={{ width: isSmall ? "100%" : undefined }}
                    onClick={() => {
                      onShowCard(value);
                    }}
                    color="primary">
                    {intl.get("my_family_bonus_cards.action_show_card")}
                  </Button>
                </Grid>
                {isSmall && (
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={0}
                    alignItems={"center"}
                    justify={"center"}>
                    <Grid item style={{ flexShrink: 0, margin: 0, padding: 0 }}>
                      <img
                        src={AddToAppleWallet}
                        style={{
                          width: isSmall ? "160px" : undefined,
                          height: "50px",
                          opacity:
                            appleWalletLoadingCardId === value.fbc_card_number
                              ? 0.5
                              : 1,
                        }}
                        onClick={() => {
                          if (
                            appleWalletLoadingCardId !== value.fbc_card_number
                          ) {
                            onAddToAppleWallet(value);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item style={{ flexShrink: 0 }}>
                      <img
                        src={AddToGoogleWallet}
                        style={{
                          width: isSmall ? "100%" : undefined,
                          height: "50px",
                          opacity:
                            googleWalletLoadingCardId === value.fbc_card_number
                              ? 0.5
                              : 1,
                        }}
                        onClick={() => {
                          if (
                            googleWalletLoadingCardId !== value.fbc_card_number
                          ) {
                            onAddToGoogleWallet(value);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {!isSmall && (
                  <>
                    <Grid item style={{ flexShrink: 0 }}>
                      <img
                        src={AddToAppleWallet}
                        style={{
                          width: isSmall ? "100%" : undefined,
                          height: "50px",
                          opacity:
                            appleWalletLoadingCardId === value.fbc_card_number
                              ? 0.5
                              : 1,
                        }}
                        onClick={() => {
                          if (
                            appleWalletLoadingCardId !== value.fbc_card_number
                          ) {
                            onAddToAppleWallet(value);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item style={{ flexShrink: 0, paddingRight: 0 }}>
                      <img
                        src={AddToGoogleWallet}
                        style={{
                          width: isSmall ? "100%" : undefined,
                          height: "50px",
                          opacity:
                            googleWalletLoadingCardId === value.fbc_card_number
                              ? 0.5
                              : 1,
                        }}
                        onClick={() => {
                          if (
                            googleWalletLoadingCardId !== value.fbc_card_number
                          ) {
                            onAddToGoogleWallet(value);
                          }
                        }}
                      />
                    </Grid>
                  </>
                )}
                {!isSmall && (
                  <Grid item style={{ flexShrink: 0, paddingLeft: 0 }}>
                    {isSmall && (
                      <Button
                        style={{
                          backgroundColor: "#FF6EA8",
                          color: "white",
                        }}
                        onClick={() => {
                          onDelete(value);
                        }}
                        color="secondary">
                        {intl.get("my_family_bonus_cards.action_delete")}
                      </Button>
                    )}
                    <IconButton
                      style={{
                        color: "#FF6EA8",
                      }}
                      onClick={() => {
                        onDelete(value);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}
