import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import { AuthenticationHelper } from "../../../utils/authenticationHelper";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContent: {
      marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    subTitle: {
      fontWeight: "normal",
      paddingRight: 80,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 6,
      },
    },
    actionContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
      },
    },
    registerButton: {
      minWidth: 168,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        marginRight: 15,
        flex: 1,
      },
    },
    loginButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
  }),
);
export default function ManageFbcGuestUserPage() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          className={classes.innerContent}
          style={{ width: "100%" }}>
          <Typography variant={"h3"} className={classes.subTitle}>
            {intl.get("my_family_bonus_cards.account_hint")}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.actionContainer} xs={12}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          className={classes.innerContent}
          style={{ width: "100%" }}>
          <Button
            color={"primary"}
            className={classes.registerButton}
            onClick={() => {
              AuthenticationHelper.redirectToSignup();
            }}>
            {intl.get("login.register")}
          </Button>
          <Button
            color={"primary"}
            className={classes.loginButton}
            onClick={() => {
              AuthenticationHelper.redirectToLogin();
            }}>
            {intl.get("login.button")}
          </Button>
        </Box>
      </Grid>
    </>
  );
}
