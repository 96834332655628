import {
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, useHistory } from "react-router";
import useQueryParams from "../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import { FamilyBonusCard } from "../../model/family.bonus.card";
import * as FamilyBonusCardActions from "../../actions/family.bonus.cards";
import * as UserActions from "../../actions/auth";
import { useDispatch } from "react-redux";
import LoadingButton from "../../components/LoadingButton";
import { useLocale } from "../../hooks/useLocale";
import { format, parseISO } from "date-fns";
import { useAppTheme } from "../../hooks/useAppTheme";
import { LoginMethod } from "../../model/model";
import ManageFbcGuestUserPage from "./fbc/ManageFbcGuestUserPage";
import { useUser } from "../../hooks/useUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: "#fff",
    },
    rootWide: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 98,
      maxWidth: theme.maxWidth,
    },

    root: {
      paddingTop: "30px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 98,
      maxWidth: 600,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    title: {
      textAlign: "center",
    },
    text: {
      marginTop: 20,
      paddingBottom: 20,
      fontSize: theme.fontSizes.mediumFont,
      textAlign: "center",
    },
    logoIconPng: {
      maxWidth: 400,
      width: "80vw",
    },
    partnersButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    divider: {
      marginTop: "10px",
    },
  }),
);

export interface Props extends RouteComponentProps<void> {}

export default function ShowFamilyBonusCardPage(props: Props) {
  const { match } = props;
  const { params } = match;
  const queryParams = useQueryParams();

  const user = useUser();

  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);

  const locale = useLocale();

  const history = useHistory();
  const appTheme = useAppTheme();

  // @ts-ignore
  const cardNumber = params.card_number;
  const vc = queryParams.get("vc");

  const [card, setCard] = useState<FamilyBonusCard>();
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addError, setAddError] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  async function loadCard() {
    setCard(undefined);
    setLoading(true);
    dispatch(
      FamilyBonusCardActions.validateFamilyBonusCard({
        cardId: cardNumber,
        cardVerifier: vc,
        completion: (response, alreadyAddedStatus) => {
          setCard(response);
          setAlreadyAdded(alreadyAddedStatus);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
          setError(true);
        },
      }),
    );
  }

  async function addCard() {
    setAddLoading(true);
    setAddError(undefined);
    dispatch<any>(
      FamilyBonusCardActions.addFamilyBonusCard({
        cardId: cardNumber,
        cardVerifier: vc,
        completion: () => {
          setAlreadyAdded(true);
          setAddLoading(false);
        },
        error: (status) => {
          setAddError(true);
          setAddLoading(false);
        },
      }),
    );
  }

  useEffect(() => {
    loadCard();
  }, []);

  if (user.login_method === LoginMethod.Guest) {
    return (
      <div className={classes.background}>
        <Grid container className={classes.rootWide} direction={"row"}>
          <ManageFbcGuestUserPage />
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.background}>
      <Grid
        container
        className={classes.root}
        direction={"column"}
        alignItems={"center"}>
        {error && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems={"center"}
            style={{ marginBottom: "20px" }}>
            <Typography variant="body2" color={"error"}>
              {intl.get(`my_family_bonus_cards.show_card.error`)}
            </Typography>
          </Grid>
        )}
        {loading && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems={"center"}
            style={{ marginBottom: "20px" }}>
            <CircularProgress size={25} />
            <Typography variant="body2" style={{ marginTop: "20px" }}>
              {intl.get("my_family_bonus_cards.show_card.loading")}
            </Typography>
          </Grid>
        )}

        {card && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container className={classes.section} spacing={0}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  alignItems={"center"}>
                  <Typography variant="h3" style={{ marginBottom: "20px" }}>
                    {intl.get("my_family_bonus_cards.title_card_link")}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justify="space-between"
                  alignItems={"flex-start"}>
                  <Grid item container direction="column" xs={6}>
                    <Typography variant={"h6"}>
                      {intl.get(
                        `my_family_bonus_cards.show_card.label_card_id`,
                      )}
                    </Typography>
                    <Typography variant={"h2"}>
                      {card.fbc_card_number}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="column"
                    alignItems={"flex-end"}
                    xs={6}>
                    <Typography variant={"h6"}>
                      {intl.get(
                        `my_family_bonus_cards.show_card.label_valid_until`,
                      )}
                    </Typography>
                    <Typography variant={"h2"}>
                      {format(parseISO(card.valid_until), "dd.MM.yyyy", {
                        locale: locale.dateFns,
                      })}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  alignItems={"center"}>
                  {card.qr_code_format === "jpg" && (
                    <img
                      src={`data:image/jpg;base64,${card.qr_code_data}`}
                      style={{ width: "100%", aspectRatio: "1" }}
                    />
                  )}
                  {card.qr_code_format === "svg" && (
                    <img
                      src={`data:image/svg+xml;base64,${card.qr_code_data}`}
                      style={{ width: "100%", aspectRatio: "1" }}
                    />
                  )}
                </Grid>

                {addError && (
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    alignItems={"center"}
                    style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="body2"
                      color={"error"}
                      style={{ textAlign: "center" }}>
                      {intl.get(`my_family_bonus_cards.error_card_adding`)}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  alignItems={"center"}>
                  <LoadingButton
                    loading={addLoading}
                    onClick={() => {
                      if (alreadyAdded) {
                        history.push("/family-bonus-cards" + appTheme.query);
                      } else {
                        addCard();
                      }
                    }}
                    buttonLabel={intl.get(
                      alreadyAdded
                        ? `my_family_bonus_cards.action_card_already_added`
                        : `my_family_bonus_cards.action_add_card_to_profile`,
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
