import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Snackbar,
  Theme,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FamilyBonusCard } from "../../../model/family.bonus.card";
import { useDispatch } from "react-redux";
import * as FamilyBonusCardActions from "../../../actions/family.bonus.cards";
import ManageFbcLoadingCard from "./ManageFbcLoadingCard";
import ManageFbcErrorCard from "./ManageFbcErrorCard";
import ManageFbcCardPlaceholder from "./ManageFbcCardPlaceholder";
import ManageFbcAddCard from "./ManageFbcAddCard";
import ManageFbcCards from "./ManageFbcCards";
import FbcCardBuyButton from "./FbcCardBuyButton";
import DialogAddCard from "./DialogAddCard";
import useDialogHandler from "../../../components/dialog/useDialogHandler";
import { Alert } from "@material-ui/lab";
import intl from "react-intl-universal";
import DialogConfirmCardDeletion from "./DialogConfirmCardDeletion";
import DialogShowCard from "./DialogShowCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContent: {
      marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
  }),
);

export default function ManageFbcPage() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [cards, setCards] = useState<FamilyBonusCard[]>();

  const addCardDialogHandler = useDialogHandler<any>("addFamilyBonusCard");
  const showCardDialogHandler = useDialogHandler<string>("showFamilyBonusCard");
  const deleteCardDialogHandler = useDialogHandler<FamilyBonusCard>(
    "deleteFamilyBonusCard",
  );

  const dispatch = useDispatch();

  const [success, setSuccess] = useState(undefined);

  const [appleWalletLoadingCard, setAppleWalletLoadingCardId] =
    useState<string>();
  const [googleWalletLoadingCard, setGoogleWalletLoadingCardId] =
    useState<string>();

  async function loadCards() {
    dispatch(
      FamilyBonusCardActions.loadFamilyBonusCards({
        includeQR: false,
        completion: (loadedCards) => {
          setCards(loadedCards);
          setLoading(false);
          setFailed(false);
        },
        error: () => {
          setLoading(false);
          setFailed(true);
        },
      }),
    );
  }

  useEffect(() => {
    loadCards();
  }, []);

  async function getAppleWalletPass(cardNumber) {
    setAppleWalletLoadingCardId(cardNumber);
    dispatch(
      FamilyBonusCardActions.downloadAppleWallet({
        cardId: cardNumber,
        completion: (res) => {
          const newBlob = new Blob([res.data], {
            type: "application/vnd.apple.pkpass",
          });

          const url = URL.createObjectURL(newBlob);
          const iframe = document.createElement("iframe");
          iframe.src = url;
          document.body.appendChild(iframe);
          setAppleWalletLoadingCardId(undefined);
        },
        error: () => {
          setAppleWalletLoadingCardId(undefined);
        },
      }),
    );
  }

  async function getGoogleWalletLink(cardNumber) {
    setGoogleWalletLoadingCardId(cardNumber);
    dispatch(
      FamilyBonusCardActions.downloadGoogleWalletLink({
        cardId: cardNumber,
        completion: (link) => {
          window.open(link);
          setGoogleWalletLoadingCardId(undefined);
        },
        error: () => {
          setGoogleWalletLoadingCardId(undefined);
        },
      }),
    );
  }

  return (
    <Grid item xs={12}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        className={classes.innerContent}
        style={{ width: "100%" }}>
        {loading && <ManageFbcLoadingCard />}
        {failed && <ManageFbcErrorCard />}
        {!loading && !failed && cards.length === 0 && (
          <ManageFbcCardPlaceholder />
        )}
        {!loading && !failed && cards.length > 0 && (
          <ManageFbcCards
            appleWalletLoadingCardId={appleWalletLoadingCard}
            googleWalletLoadingCardId={googleWalletLoadingCard}
            cards={cards}
            onShowCard={(card) => {
              showCardDialogHandler.open(card.fbc_card_number);
            }}
            onAddToAppleWallet={(card) => {
              getAppleWalletPass(card.fbc_card_number);
            }}
            onAddToGoogleWallet={(card) => {
              getGoogleWalletLink(card.fbc_card_number);
            }}
            onDelete={(card) => {
              deleteCardDialogHandler.open(card);
            }}
          />
        )}
        {!loading && !failed && (
          <ManageFbcAddCard
            onAddCard={() => {
              addCardDialogHandler.open();
            }}
          />
        )}

        <FbcCardBuyButton />
      </Box>

      <DialogAddCard
        handler={addCardDialogHandler}
        onSuccessfullyAdded={() => {
          setSuccess(intl.get(`my_family_bonus_cards.add_card.success`));
          loadCards();
        }}
      />

      <DialogConfirmCardDeletion
        handler={deleteCardDialogHandler}
        onSuccessfullyDeleted={() => {
          setSuccess(intl.get(`my_family_bonus_cards.delete_card.success`));
          loadCards();
        }}
      />

      <DialogShowCard handler={showCardDialogHandler} />

      {success && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={3000}
          onClose={() => {
            setSuccess(undefined);
          }}>
          <Alert
            onClose={() => {
              setSuccess(undefined);
            }}
            severity="success">
            {success}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
}
