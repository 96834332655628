import { IEvent } from "../../model/event";
import Grid from "@material-ui/core/Grid";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import * as React from "react";
import { FamilyBonusCardOffer } from "../../model/family.bonus.card";
import useDialogHandler from "../dialog/useDialogHandler";
import DialogShowFbcOfferDetails, {
  OfferDetailsInput,
} from "../../pages/user/fbc/DialogShowFbcOfferDetails";
import FbcOfferView from "../../pages/user/fbc/FbcOfferView";
import DialogFbcOfferRedemption from "../../pages/user/fbc/DialogFbcOfferRedemption";
import DialogFbcOfferRedemptionNotAvailableNotLoggedIn from "../../pages/user/fbc/DialogFbcOfferRedemptionNotAvailableNotLoggedIn";
import { LoginMethod } from "../../model/model";
import { useUser } from "../../hooks/useUser";
import FbcCardBuyButton from "../../pages/user/fbc/FbcCardBuyButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("md")]: {
        marginRight: 32,
      },
    },
    header: {
      marginTop: 16,
    },
    offerRow: {
      [theme.breakpoints.up("md")]: {
        marginRight: 32,
      },
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: theme.separator,
    },
    actionContainer: {
      [theme.breakpoints.up("md")]: {
        marginRight: 32,
      },
      marginTop: 16,
      display: "flex",
      justifyContent: "center",
    },
    actionContainerLink: {
      marginTop: 16,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
    actionButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
    offerTitle: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.mediumFont,
    },
    offerValidity: {
      fontSize: theme.fontSizes.smallFont,
    },
    offerSavings: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.mediumFont,
      color: "#FF6EA8",
    },
    showOfferDetails: {
      textDecoration: "underline",
      textUnderlineOffset: 4,
      cursor: "pointer",
      fontSize: theme.fontSizes.smallFont,
      fontWeight: "bold",
    },
  }),
);

interface Props {
  event: IEvent;
}

export default function EventFamilyBonusCardOfferInfos(props: Props) {
  const { event } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const user = useUser();

  const showOfferDialogHandler = useDialogHandler<OfferDetailsInput>(
    "showFamilyBonusCardOffer",
  );

  const offerRedemptionDialogHandler = useDialogHandler<FamilyBonusCardOffer>(
    "offerRedemptionDialog",
  );
  const notifyAboutLoginDialogHandler = useDialogHandler<void>(
    "notifyAboutLoginDialog",
  );

  if (
    !event.family_bonus_card_offers ||
    event.family_bonus_card_offers.length === 0
  ) {
    return null;
  }

  function redeemOffer(offer: FamilyBonusCardOffer) {
    if (user.login_method === LoginMethod.Guest) {
      notifyAboutLoginDialogHandler.open();
    } else {
      offerRedemptionDialogHandler.open(offer);
    }
  }

  return (
    <>
      <Grid container xs={12} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant={"h3"} className={classes.header}>
            {intl.get("event_details.family_bonus_card_offers_title")}
          </Typography>
        </Grid>
        {event.family_bonus_card_offers.map((offer) => {
          return (
            <FbcOfferView
              offer={offer}
              detailView={false}
              onShowDetails={() => {
                showOfferDialogHandler.open({
                  offer,
                  onRedeem: (offerToRedeem) => {
                    redeemOffer(offerToRedeem);
                  },
                });
              }}
              onRedeem={() => {
                redeemOffer(offer);
              }}
            />
          );
        })}

        {user.family_bonus_card_count === 0 && (
          <FbcCardBuyButton reduced={true} />
        )}
      </Grid>

      <DialogShowFbcOfferDetails handler={showOfferDialogHandler} />

      <DialogFbcOfferRedemption handler={offerRedemptionDialogHandler} />

      <DialogFbcOfferRedemptionNotAvailableNotLoggedIn
        handler={notifyAboutLoginDialogHandler}
      />
    </>
  );
}
