import { Helmet } from "react-helmet";
import { getCanonicalUrl } from "../../../utils/utils";
import intl from "react-intl-universal";
import React from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useUser } from "../../../hooks/useUser";
import { LoginMethod } from "../../../model/model";
import { isMobile } from "react-device-detect";
import ManageFbcGuestUserPage from "./ManageFbcGuestUserPage";
import ManageFbcPage from "./ManageFbcPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: "#fff",
    },
    root: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 98,
      maxWidth: theme.maxWidth,
    },
    content: {
      maxWidth: theme.maxWidth,
      marginLeft: "auto",
      marginRight: "auto",
      overflow: "hidden",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: isMobile ? 0 : 56,
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: isMobile ? 0 : 21,
        paddingBottom: 21,
      },
    },
    innerContent: {
      marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    subTitle: {
      fontWeight: "normal",
      paddingRight: 80,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 6,
      },
    },
    actionContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
      },
    },
    registerButton: {
      minWidth: 168,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        marginRight: 15,
        flex: 1,
      },
    },
    loginButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
    cardCell: {
      borderBottom: 0,
    },
    cardCellCenter: {
      textAlign: "center",
      borderBottom: 0,
    },
  }),
);

export default function MyFamilyBonusCardsPage() {
  const user = useUser();

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.background}>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {intl.get(`my_family_bonus_cards.title`) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <Grid container className={classes.root} direction={"row"}>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            className={classes.content}
            style={{ width: "100%" }}>
            <Typography variant={"h2"}>
              {intl.get("my_family_bonus_cards.title")}
            </Typography>
          </Box>
        </Grid>

        {user.login_method === LoginMethod.Guest && <ManageFbcGuestUserPage />}

        {user.login_method !== LoginMethod.Guest && <ManageFbcPage />}
      </Grid>
    </div>
  );
}
