import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import intl from "react-intl-universal";
import * as React from "react";
import LoadingButton from "../../../components/LoadingButton";
import useDialogReset from "../../../components/dialog/useDialogReset";
import * as FamilyBonusCardActions from "../../../actions/family.bonus.cards";
import { FamilyBonusCard } from "../../../model/family.bonus.card";
import { useLocale } from "../../../hooks/useLocale";
import FbcCardLayout from "./FbcCardLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
      paddingTop: 0,
      overflow: "hidden",
    },
  }),
);

export default function DialogShowCard({
  handler,
}: {
  handler: DialogHandler<string>;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const [card, setCard] = useState<FamilyBonusCard>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useDialogReset(handler, () => {
    setCard(undefined);
    setError(false);
    setLoading(false);
  });

  async function loadCard() {
    if (!handler.payload || !handler.isOpen) {
      return;
    }
    setLoading(true);
    dispatch(
      FamilyBonusCardActions.getFamilyBonusCardInfo({
        cardId: handler.payload,
        completion: (response) => {
          setCard(response);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
          setError(true);
        },
      }),
    );
  }

  useEffect(() => {
    loadCard();
  }, [handler.payload, handler.isOpen]);

  if (!handler.payload) {
    return null;
  }

  return (
    <HandlerDialog
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogContent className={classes.dialogContent}>
        {error && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems={"center"}
            style={{ marginBottom: "20px" }}>
            <Typography variant="body2" color={"error"}>
              {intl.get(`my_family_bonus_cards.show_card.error`)}
            </Typography>
          </Grid>
        )}
        {loading && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems={"center"}
            style={{ marginBottom: "20px" }}>
            <CircularProgress size={25} />
            <Typography variant="body2" style={{ marginTop: "20px" }}>
              {intl.get("my_family_bonus_cards.show_card.loading")}
            </Typography>
          </Grid>
        )}

        {card && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FbcCardLayout
                card={card}
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignItems={"center"}
          style={{ marginBottom: "20px" }}>
          <LoadingButton
            loading={false}
            onClick={() => {
              handler.close();
            }}
            buttonLabel={intl.get(
              `my_family_bonus_cards.show_card.action_close`,
            )}
          />
        </Grid>
      </DialogActions>
    </HandlerDialog>
  );
}
