import { Dispatch } from "redux";
import axios, { AxiosResponse } from "axios";
import {
  FamilyBonusCard,
  OfferRedeemabilityCheckResponse,
} from "../model/family.bonus.card";
import { ActionType } from "../model/model";

export const loadFamilyBonusCards =
  ({
    includeQR,
    completion,
    error,
  }: {
    includeQR: boolean;
    completion: (cards: FamilyBonusCard[]) => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.get(
        `/api/b2c/private/user/family-bonus-cards?includeQR=${includeQR}`,
      );
      if (res.data) {
        dispatch({
          type: ActionType.UPDATE_USER_FBC_COUNT,
          payload: {
            has_family_bonus_cards: res.data.cards.length > 0,
            family_bonus_card_count: res.data.cards.length,
          },
        });

        completion(res.data.cards);
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

export const addFamilyBonusCard =
  ({
    cardId,
    cardVerifier,
    completion,
    error,
  }: {
    cardId: string;
    cardVerifier?: string;
    completion: () => void;
    error: (status: string) => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.post(`/api/b2c/private/user/family-bonus-cards`, {
        card_number: cardId,
        card_verifier: cardVerifier,
      });
      if (res.data && res.data.status === "success") {
        if (
          res.data.has_family_bonus_cards &&
          res.data.family_bonus_card_count
        ) {
          dispatch({
            type: ActionType.UPDATE_USER_FBC_COUNT,
            payload: {
              has_family_bonus_cards: res.data.has_family_bonus_cards,
              family_bonus_card_count: res.data.family_bonus_card_count,
            },
          });
        }
        completion();
      } else {
        if (res.data && res.data.status) {
          error(res.data.status);
        } else {
          error("failed");
        }
      }
    } catch (e) {
      error("failed");
    }
  };

export const validateFamilyBonusCard =
  ({
    cardId,
    cardVerifier,
    completion,
    error,
  }: {
    cardId: string;
    cardVerifier: string;
    completion: (card: FamilyBonusCard, alreadyAdded: boolean) => void;
    error: (status: string) => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.post(
        `/api/b2c/private/user/family-bonus-cards/validate`,
        {
          card_number: cardId,
          card_verifier: cardVerifier,
        },
      );
      if (
        res.data &&
        (res.data.status === "success" ||
          res.data.status === "already_added") &&
        res.data.card
      ) {
        completion(res.data.card, res.data.status === "already_added");
      } else {
        if (res.data && res.data.status) {
          error(res.data.status);
        } else {
          error("failed");
        }
      }
    } catch (e) {
      error("failed");
    }
  };

export const deleteFamilyBonusCard =
  ({
    cardId,
    completion,
    error,
  }: {
    cardId: string;
    completion: () => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.delete(
        `/api/b2c/private/user/family-bonus-cards/${cardId}`,
      );
      if (res.data && res.data.status === "ok") {
        if (
          res.data.has_family_bonus_cards &&
          res.data.family_bonus_card_count
        ) {
          dispatch({
            type: ActionType.UPDATE_USER_FBC_COUNT,
            payload: {
              has_family_bonus_cards: res.data.has_family_bonus_cards,
              family_bonus_card_count: res.data.family_bonus_card_count,
            },
          });
        }
        completion();
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

export const getFamilyBonusCardInfo =
  ({
    cardId,
    completion,
    error,
  }: {
    cardId: string;
    completion: (card: FamilyBonusCard) => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.get(
        `/api/b2c/private/user/family-bonus-cards/${cardId}`,
      );
      if (res.data && res.data.status === "ok" && res.data.card) {
        completion(res.data.card);
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

export const downloadAppleWallet =
  ({
    cardId,
    completion,
    error,
  }: {
    cardId: string;
    completion: (data: AxiosResponse) => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.get(
        `/api/b2c/private/user/family-bonus-cards/${cardId}/apple_wallet_pass`,
        { responseType: "blob" },
      );
      if (res) {
        completion(res);
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

export const downloadGoogleWalletLink =
  ({
    cardId,
    completion,
    error,
  }: {
    cardId: string;
    completion: (link: string) => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.get(
        `/api/b2c/private/user/family-bonus-cards/${cardId}/google_wallet_link`,
      );
      if (res && res.data && res.data.link) {
        completion(res.data.link);
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

export const checkOfferRedeemability =
  ({
    offer_id,
    completion,
    error,
  }: {
    offer_id: string;
    completion: (data: OfferRedeemabilityCheckResponse) => void;
    error: () => void;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.post(
        `/api/b2c/private/user/family-bonus-cards/check-offer`,
        {
          offer_id,
        },
      );
      if (res.data && res.data.cards && res.data.offer) {
        completion(res.data);
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };
