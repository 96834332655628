import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import { FamilyBonusCardOffer } from "../../../model/family.bonus.card";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import * as React from "react";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import intl from "react-intl-universal";
import LoadingButton from "../../../components/LoadingButton";
import FbcOfferView from "./FbcOfferView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
      paddingTop: 0,
      overflow: "hidden",
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    divider: {
      marginTop: "10px",
    },
    offerValidity: {
      fontSize: theme.fontSizes.smallFont,
    },
    offerSavings: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.mediumFont,
      color: "#FF6EA8",
    },
  }),
);

export interface OfferDetailsInput {
  offer: FamilyBonusCardOffer;
  onRedeem: (offer: FamilyBonusCardOffer) => void;
}

export default function DialogShowFbcOfferDetails({
  handler,
}: {
  handler: DialogHandler<OfferDetailsInput>;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (!handler.payload) {
    return null;
  }

  return (
    <HandlerDialog
      onBackdropClick={() => {
        handler.close();
      }}
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogTitle>
        {intl.get("event_details.dialog_show_offer.title")}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <FbcOfferView
          offer={handler.payload.offer}
          detailView={true}
          onRedeem={() => {
            handler.payload.onRedeem(handler.payload.offer);
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={
            handler.payload && !handler.payload.offer.currently_redeemable
          }
          loading={false}
          onClick={() => {
            handler.payload.onRedeem(handler.payload.offer);
            handler.close();
          }}
          buttonLabel={intl.get(
            "event_details.dialog_show_offer.action_redeem",
          )}
        />
        <Button
          color="secondary"
          onClick={() => {
            handler.close();
          }}>
          {intl.get("event_details.dialog_show_offer.action_close")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
