import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import intl from "react-intl-universal";
import React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardCellCenter: {
      textAlign: "center",
      borderBottom: 0,
    },
  }),
);

export default function ManageFbcCardPlaceholder() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.cardCellCenter}>
            {intl.get("my_family_bonus_cards.status_no_cards")}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
