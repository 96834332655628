import { ISection } from "./section";
import { IEvent } from "./event";
import { sanitizeLinkUrl } from "../utils/urlUtils";

const moment = require("moment");

export function mapErrorFromException(exception: any): Error {
  let statusCode = null;
  if (exception.response) {
    statusCode = exception.response.status;
  }
  let errorCode = null;
  if (exception.response && exception.response.data) {
    errorCode = exception.response.data.error_code;
  }
  let serverError = null;
  if (exception.response && exception.response.data) {
    serverError = exception.response.data.error;
  }

  return {
    error: serverError,
    statusCode,
    error_code: errorCode,
    response: exception.response,
  };
}

export enum TrackingActionType {
  accepted = "accepted",
  rejected = "rejected",
  opened = "opened",
  deleted = "deleted",
  booked = "booked",
  undoAccepted = "undo-accepted",
  undoRejected = "undo-rejected",
}

export interface Error {
  error: string | any;
  statusCode?: Number | null;
  response?: any | null;
  error_code?: string | null;
}

export class EventAction {
  eventId: string;

  accepted: Boolean = false;

  rejected: Boolean = false;
}
export interface SeoPage {
  url: string;
  title: string;
  headline: string;
  description: string;
  bottom_description: string;
  meta_description: string;
  main_media_url: string;
}

export class EventState {
  sections: ISection[] = [];

  countries: Country[] = [];

  cities: City[] = [];

  loading: Boolean = false;

  failed: Boolean = false;

  error?: Error | null;

  loaded: Boolean = false;

  autoDeterminedCities: City[];

  defaultCountryId = "";

  defaultCityId = "";

  scrollPosition = 0;

  heroImagesLoaded = false;
}

export class FavoritesState {
  favorites: IEvent[];

  loading = false;

  failed = false;

  error?: Error | null;
}

export class SeoState {
  seo_page: SeoPage | null = null;

  seo_pages: SeoPage[] = [];

  events: IEvent[] = [];

  loading: Boolean = false;

  failed: Boolean = false;

  error?: Error | null;

  loaded: Boolean = false;
}

export enum LoginMethod {
  Facebook = "Facebook",
  Google = "Google",
  Email = "Email",
  Guest = "Guest",
}

export interface User {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  organization_unit: string;
  b2c_profile: B2CProfile;
  status: string;
  login_method: string;
  children: Child[];
  has_family_bonus_cards: boolean;
  family_bonus_card_count: number;
}

export interface PartialB2CProfile {
  birth_year?: number | null;
  gender?: string | null;
}

export interface B2CProfile {
  user_id: number;
  birth_year?: number | null;
  gender?: string | null;
  completed: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export interface Category {
  category_id: string;
  identifier: string;
}

export interface Child {
  child_id: string;
  birthday: Date;
}

export interface Pagination {
  total_size: number;
  total_pages: number;
  page_size: number;
  current_page: number;
}

export class PaginationImpl implements Pagination {
  total_size = 0;

  total_pages = 0;

  page_size = 0;

  current_page = -1;
}

export class CategoryState {
  events: IEvent[] = [];

  loading: Boolean = false;

  failed: Boolean = false;

  loaded: Boolean = false;

  pagination: Pagination = new PaginationImpl();

  numberOfCurrentlyRemovedEvents = 0;

  scrollPosition = 0;
}

export class SearchState {
  events: IEvent[] = [];

  loading: Boolean = false;

  failed: Boolean = false;

  loaded: Boolean = false;

  pagination: Pagination = new PaginationImpl();
}

export class EventDetailState {
  selectedEvent: IEvent;

  loading: Boolean = false;

  failed: Boolean = false;

  errorNotFound: Boolean = false;

  shouldRedirect: Boolean = false;
}

export class EditUserState {
  loading = false;

  saving = false;

  failed = false;

  error?: Error | null;

  passwordChanged = false;

  cancellationError: string | null;

  updatePasswordError?: Error | null;
}

export interface Authentication {
  needsToValidate: Boolean;
  user: User;
  authenticated: Boolean;
  loading: Boolean;
  error: Error | null;
  forgotPasswordTriggered: Boolean;
  waitingForActivation: Boolean;
}

export class UserLocationState {
  selectedType?: "geolocation" | "manual";

  geoLocation?: GeoLocation = {
    locationState: "init",
  };

  manualLocation?: ManualLocation;
}

export interface GeoLocation {
  latitude?: number;
  longitude?: number;
  permission?: GeoLocationPermissionState;
  locationState: GeoLocationState;
  timestamp?: number;
}

export interface ManualLocation {
  latitude?: number;
  longitude?: number;
  timestamp?: number;
  name?: string;
}

export interface AnalyticsState {
  custom_dimensions: [];
}

export type GeoLocationPermissionState =
  | "prompt"
  | "denied"
  | "granted"
  | "not_available";

export type GeoLocationState =
  | "init"
  | "requested"
  | "determined"
  | "not_available"
  | "no_permission";

export enum EventType {
  Dedicated = "Dedicated",
  Recurring = "Recurring",
}

export interface DedicatedTime {
  start_date: string;
  end_date: string;
}

export interface AvailabilityRange {
  start_date: Date;
  end_date?: Date;
  active_range: boolean;
  hours: OpeningHours;
}

export interface OpeningHours {
  mon?: OpeningHour[];
  tue?: OpeningHour[];
  wed?: OpeningHour[];
  thu?: OpeningHour[];
  fri?: OpeningHour[];
  sat?: OpeningHour[];
  sun?: OpeningHour[];
  hol?: OpeningHour[];
}

export interface OpeningHour {
  start: Hour;
  end: Hour;
}

export interface Hour {
  HH: string;
  mm: string;
}

export enum ActionType {
  GET_EVENTS,
  RESET_EVENTS,
  LOAD_EVENT,
  SELECT_EVENT,
  GET_FAVORITES,

  GET_CATEGORY_EVENTS,
  REMOVE_FROM_FAVORITES,
  ADD_TO_FAVORITES,

  SEARCH_EVENTS,

  SET_LOCATION,
  SET_GEOLOCATION,
  UPDATE_GEOLOCATION,
  SET_MANUAL_LOCATION,

  FETCHING_USER,
  FETCH_USER,

  UPDATE_USER,
  UPDATE_USER_FBC_COUNT,
  UPDATE_PASSWORD,
  RESET_UPDATE_PASSWORD,

  SETUP_NEEDED,
  SETUP_FINISHED,
  SETUP_ERROR,

  LOGOUT,
  GET_CATEGORIES,
  ACCOUNT_DEACTIVATION_ERROR,

  UPDATE_EVENT_FILTER,

  TRACK_ACTION,

  LOAD_SEO_PAGES,
  GET_SEO_EVENTS,
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface Country {
  id: string;
  code: string;
  name: string;
}

export interface City {
  id: string;
  name: string;
  country_code: string;
}

export class EventFilterState {
  selectedCityId = "";

  startDate: Date = moment().toDate();

  endDate: Date = moment("2100-12-31").toDate();

  editingStartDate: Date;

  editingEndDate: Date;

  filterByLocation: boolean | undefined = undefined;
}

export function createEndingAtOpeningHoursString(openingHour: OpeningHour) {
  return `${openingHour.end.HH}:${openingHour.end.mm}`;
}

export function createOpeningHoursString(hour: Hour | null) {
  if (hour && hour.HH && hour.HH.length > 0) {
    return `${hour.HH}:${hour.mm}`;
  }
  return "";
}

export function hasDailyOpeningHours(event: IEvent) {
  if (event.availability) {
    for (const range of event.availability) {
      if (
        range.hours.mon ||
        range.hours.tue ||
        range.hours.wed ||
        range.hours.thu ||
        range.hours.fri ||
        range.hours.sat ||
        range.hours.sun ||
        range.hours.hol
      ) {
        return true;
      }
    }
  }
  return false;
}

export function createBookingUrlLink(link: string, type: string) {
  if (type === "LINK") {
    return sanitizeLinkUrl(link);
  }
  return `mailto:${link}`;
}

export function getTargetForBookingUrl(type: string) {
  if (type !== "LINK") {
    return "_self";
  }
  return "_blank";
}

export function videoPreviewImageUrl(url: string) {
  const lastIndex = url.lastIndexOf(".");
  if (lastIndex !== -1) {
    const pngURL = url.substring(0, lastIndex) + ".png";
    const uploadIndex = pngURL.indexOf("/upload/");
    if (uploadIndex !== -1) {
      return (
        pngURL.substring(0, uploadIndex + 8) +
        "so_0.0," +
        pngURL.substring(uploadIndex + 8)
      );
    }
    return pngURL;
  }
  return url;
}

export function resizedImageURL(
  url: string,
  desktop: boolean,
  pixelRatio: number,
  large: boolean,
) {
  if (!url) {
    return url;
  }
  if (url.indexOf("cloudinary.com") === -1) {
    if (url.indexOf("http://familienausflug.discoverize.com/") !== -1) {
      return url.replace(
        "http://familienausflug.discoverize.com/",
        "https://familienausflug.discoverize.com/",
      );
    }
    return url;
  }

  let modifiedUrl = url;

  let desiredResolution = 250;
  if (large) {
    desiredResolution = 500;
  }
  if (pixelRatio > 1) {
    desiredResolution = desiredResolution * Math.max(2, Math.ceil(pixelRatio));
  }

  modifiedUrl = modifiedUrl.replace(
    ",h_1000,w_1000/",
    `,h_${desiredResolution},w_${desiredResolution}/`,
  );

  return modifiedUrl;
}

export function normalizedImageURL(url: string) {
  if (url.indexOf("http://familienausflug.discoverize.com/") !== -1) {
    return url.replace(
      "http://familienausflug.discoverize.com/",
      "https://familienausflug.discoverize.com/",
    );
  }
  return url;
}

export function resizedImageURLAsWebp(
  url: string,
  desktop: boolean,
  pixelRatio: number,
  large: boolean,
) {
  if (!url) {
    return null;
  }
  if (url.indexOf("cloudinary.com") === -1) {
    return null;
  }
  let modifiedUrl = url;

  let desiredResolution = 250;
  if (large) {
    desiredResolution = 500;
  }
  if (pixelRatio > 1) {
    desiredResolution = desiredResolution * Math.max(2, Math.ceil(pixelRatio));
  }

  modifiedUrl = modifiedUrl.replace(
    ",h_1000,w_1000/",
    `,h_${desiredResolution},w_${desiredResolution}/`,
  );

  return modifiedUrl.replace(".jpg", ".webp").replace(".png", ".webp");
}

export function prepareCustomDimensionsForUser(user: User): any[] {
  let gender = "unknown";
  if (user.b2c_profile && user.b2c_profile.gender) {
    gender = user.b2c_profile.gender;
  }
  let ageGroup = "unknown";
  if (user.b2c_profile && user.b2c_profile.birth_year) {
    const age =
      Number.parseInt(moment().format("YYYY")) - user.b2c_profile.birth_year;
    if (age < 20) {
      ageGroup = "u20";
    } else if (age >= 20 && age <= 29) {
      ageGroup = "20-29";
    } else if (age >= 30 && age <= 39) {
      ageGroup = "30-39";
    } else if (age >= 40 && age <= 55) {
      ageGroup = "40-55";
    } else {
      ageGroup = "o50";
    }
  }
  const userId = user.user_id;
  const userType = user.login_method;
  const childCount = `${user.children.length}`;
  let children = [];
  for (const ch of user.children) {
    const childAge = moment().diff(ch.birthday, "years", false);
    children.push(childAge);
  }
  children = children.sort((a, b) => {
    if (a <= b) {
      return -1;
    } else {
      return +1;
    }
  });

  const parameters = [
    { id: 1, value: userType },
    { id: 2, value: userId },
    { id: 3, value: ageGroup },
    { id: 4, value: gender },
    { id: 5, value: `${childCount}__${children.join("_")}` },
  ];
  return parameters;
}
