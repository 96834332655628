import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import FbcBuyLink from "../../../assets/images/fbc_buy_link.png";
import FbcBuyMoreLink from "../../../assets/images/fbc_buy_more_link.png";
import { useUser } from "../../../hooks/useUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fbcLinkSection: {
      cursor: "pointer",
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 32,
      paddingTop: 32,
      [theme.breakpoints.down("md")]: {
        paddingBottom: 16,
      },
    },
    fbcLinkSectionReduced: {
      cursor: "pointer",
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 0,
      paddingTop: 16,
      [theme.breakpoints.down("md")]: {
        paddingBottom: 0,
      },
    },
    fbcLinkImage: {
      maxWidth: "100%",
      maxHeight: "250px",
      [theme.breakpoints.down("md")]: {
        maxHeight: "200px",
      },
    },
    fbcLinkImageReduced: {
      maxWidth: "100%",
      maxHeight: "200px",
      [theme.breakpoints.down("md")]: {
        maxHeight: "150px",
      },
    },
    fbcLinkImageVeryReduced: {
      maxWidth: "100%",
      maxHeight: "150px",
      [theme.breakpoints.down("md")]: {
        maxHeight: "120px",
      },
    },
  }),
);

interface Props {
  reduced?: boolean;
  veryReduced?: boolean;
}

export default function FbcCardBuyButton(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const user = useUser();

  return (
    <Grid
      item
      container
      justify={"center"}
      direction={"row"}
      className={
        props.reduced === true
          ? classes.fbcLinkSectionReduced
          : classes.fbcLinkSection
      }>
      <Grid item>
        <img
          className={
            props.veryReduced === true
              ? classes.fbcLinkImageVeryReduced
              : props.reduced === true
              ? classes.fbcLinkImageReduced
              : classes.fbcLinkImage
          }
          src={user.family_bonus_card_count === 0 ? FbcBuyLink : FbcBuyMoreLink}
          onClick={() => {
            window.open("https://shop.familybonuscard.at");
          }}
        />
      </Grid>
    </Grid>
  );
}
