import intl from "react-intl-universal";
import React from "react";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      display: "flex",
      justifyContent: "center",
    },
    actionButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
  }),
);

export default function ManageFbcAddCard({
  onAddCard,
}: {
  onAddCard: () => void;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid item className={classes.actionContainer} xs={12}>
      <Button
        color={"primary"}
        className={classes.actionButton}
        onClick={onAddCard}
        startIcon={<AddIcon />}>
        {intl.get("my_family_bonus_cards.action_add_card")}
      </Button>
    </Grid>
  );
}
